import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [];

export const dictionary = {
		"/": [~4],
		"/adminnn": [15],
		"/adminnn/birth-detail/[id]/result": [~16],
		"/adminnn/birth-detail/[id]/update": [~17],
		"/adminnn/config/payment/provider": [18],
		"/adminnn/order-item/[id]/destiny-result": [~19],
		"/adminnn/payment": [~20],
		"/adminnn/users": [~21],
		"/adminnn/users/[id]": [~22],
		"/edge": [~23,[2]],
		"/edge/streaming": [~24,[2]],
		"/helper/liff/add-friend": [~25],
		"/helper/liff/close": [26],
		"/helper/paysolution/payment-page": [27],
		"/helper/redirect/instagram": [28],
		"/node": [~29,[3]],
		"/node/streaming": [~30,[3]],
		"/sentry-example": [31],
		"/[lang]/campaign/line-share/receive": [5],
		"/[lang]/campaign/line-share/send": [6],
		"/[lang]/campaign/reward": [7],
		"/[lang]/landing": [8],
		"/[lang]/orders": [9],
		"/[lang]/orders/bazi/book-reading": [~10],
		"/[lang]/orders/bazi/couple": [~11],
		"/[lang]/ziamzee/result/temple/[templeId]/ziamzeeNumber/[ziamzeeNumber]": [~12],
		"/[lang]/ziamzee/select-temple": [13],
		"/[lang]/ziamzee/shake": [~14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';